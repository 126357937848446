import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { graphql } from "gatsby"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import Page404 from "../componets/Page404/Page404"
import "./globals.css"

export const query = graphql`
  query {
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Home(props) {
  const {
    data: { logo },
  } = props
  return (
    <div>
      <SiteMetadata />
      <Header {...props} logo={logo} />
      <Page404 />
      <Footer />
    </div>
  )
}
